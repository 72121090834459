import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 品种案例
 */
import Layout from "@/layout";
var orderNo = 4;
export default {
  path: "/varietyCase",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "品类案例管理",
    icon: "commodities_icon",
    selectedIcon: " ",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/varietyCase"
  },
  children: [{
    path: "/caseType",
    name: "caseType",
    component: function component() {
      return import("@/views/customizeOrder/customizeOrderList");
    },
    meta: {
      title: "案例类型",
      noCache: true
    }
  }]
};