import "core-js/modules/es.array.join.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import axios from 'axios';
var pendingMap = new Map();

/**
 * 生成每个请求唯一的键
 * @param {*} config 
 * @returns string
 */
export function getPendingKey(config) {
  var url = config.url,
    method = config.method,
    params = config.params,
    data = config.data;
  if (typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
  return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
}

/**
 * 储存每个请求唯一值, 也就是cancel()方法, 用于取消请求
 * @param {*} config 
 */
export function addPending(config) {
  var pendingKey = getPendingKey(config);
  config.cancelToken = config.cancelToken || new axios.CancelToken(function (cancel) {
    if (!pendingMap.has(pendingKey)) {
      pendingMap.set(pendingKey, cancel);
    }
  });
}

/**
 * 删除重复的请求
 * @param {*} config 
 */
export function removePending(config) {
  var pendingKey = getPendingKey(config);
  if (pendingMap.has(pendingKey)) {
    var cancelToken = pendingMap.get(pendingKey);
    cancelToken(pendingKey);
    pendingMap.delete(pendingKey);
  }
}