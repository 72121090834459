import _objectSpread from "/Users/twoha/Desktop/longtou-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RightPanel from '@/components/RightPanel';
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components';
import ResizeMixin from './mixin/ResizeHandler';
import { mapState } from 'vuex';
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: 'Layout',
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    RightPanel: RightPanel,
    Breadcrumb: Breadcrumb,
    Settings: Settings,
    TagsView: TagsView,
    AppMain: AppMain
  },
  mixins: [ResizeMixin],
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    },
    hideBreadcrumb: function hideBreadcrumb(state) {
      return state.settings.hideBreadcrumb;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    },
    isShow: function isShow() {
      var _this = this;
      var flag = true;
      ['/stageHome'].forEach(function (path) {
        if (_this.$route.path.indexOf(path) !== -1) {
          flag = false;
        }
      });
      return flag;
    }
  }),
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};