import _objectSpread from "/Users/twoha/Desktop/longtou-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.to-fixed.js";
import { mapGetters } from "vuex";
var mixin = {
  computed: _objectSpread(_objectSpread({}, mapGetters(["userInfo"])), {}, {
    isManage: function isManage() {
      return this.userInfo.roleName === '管理员';
    }
  }),
  data: function data() {
    return {
      isMain: true
    };
  },
  methods: {
    filterMoney: function filterMoney(val) {
      return '¥' + (val || 0).toFixed(2);
    },
    moneyPoint: function moneyPoint(val) {
      return (val || 0).toFixed(2);
    }
  }
};
export default mixin;