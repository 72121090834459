import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 商品订单管理
 */

import Layout from "@/layout";
var orderNo = 4;
export default {
  path: "/order",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "商品订单管理",
    icon: "order_icon",
    selectedIcon: "",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/order"
  },
  children: [{
    path: "/saleOrder",
    name: "saleOrder",
    component: function component() {
      return import("@/views/order/saleOrder/index");
    },
    meta: {
      title: "商品销售订单",
      noCache: true
    },
    children: [{
      path: "/orderInfo",
      name: "orderInfo",
      component: function component() {
        return import("@/views/order/saleOrder/orderInfo");
      },
      hidden: true,
      meta: {
        title: "商品订单详情",
        noCache: true
      }
    }]
  }, {
    path: "/sendGoods",
    name: "sendGoods",
    component: function component() {
      return import("@/views/order/sendGoods/index");
    },
    meta: {
      title: "商品订单发货",
      noCache: true
    },
    children: [{
      path: "/send",
      name: "send",
      component: function component() {
        return import("@/views/order/sendGoods/send");
      },
      hidden: true,
      meta: {
        title: "商品发货",
        noCache: true
      }
    }, {
      path: "/importSend",
      name: "importSend",
      component: function component() {
        return import("@/views/order/sendGoods/importSend");
      },
      hidden: true,
      meta: {
        title: "商品批量发货",
        noCache: true
      }
    }, {
      path: "/orderInfoSend",
      name: "orderInfoSend",
      component: function component() {
        return import("@/views/order/saleOrder/orderInfo");
      },
      hidden: true,
      meta: {
        title: "商品订单详情",
        noCache: true
      }
    }]
  }, {
    path: "/sendList",
    name: "sendList",
    component: function component() {
      return import("@/views/order/sendList/index");
    },
    meta: {
      title: "商品发货单",
      noCache: true
    }
  }, {
    path: "/evaluate",
    name: "evaluate",
    component: function component() {
      return import("@/views/order/evaluate/index");
    },
    meta: {
      title: "商品评价管理",
      noCache: true
    }
  }, {
    path: "/saleAfter",
    name: "saleAfter",
    component: function component() {
      return import("@/views/order/saleAfter/index");
    },
    meta: {
      title: "售后管理",
      noCache: true
    },
    children: [{
      path: "/saleAfterInfo",
      name: "saleAfterInfo",
      component: function component() {
        return import("@/views/order/saleAfter/saleAfterInfo");
      },
      hidden: true,
      meta: {
        title: "售后详情",
        noCache: true
      }
    }]
  }]
};