import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
export function area(value) {
  var realVal = value ? (value / 1000000).toString() : '0';
  if (realVal.indexOf('.') == -1) {
    realVal += '.000';
  } else {
    realVal = parseFloat(value / 1000000).toFixed(3);
  }
  return realVal;
}
export function name(name) {
  var reg = /[A-Za-z]/;
  if (name.split("").every(function (i) {
    return reg.test(i);
  })) {
    return name[0].toUpperCase();
  }
  if (name.length === 1 || name.length === 2) {
    return name;
  }
  if (name.length === 3) {
    return name.slice(1, 3);
  }
  if (name.length === 4) {
    return name.slice(2, 4);
  }
  return name.slice(0, 2);
}
export function thickness(value) {
  var realVal = value ? value.toString() : '0';
  if (realVal.indexOf('.') == -1) {
    realVal += '.0';
  } else {
    realVal = parseFloat(value).toFixed(1);
  }
  return realVal;
}
export function phone(value) {
  if (!value) return "-";
  value = String(value);
  var arr = value.split("");
  arr.splice(3, 4, "****");
  return arr.join("");
}
export function percentage(value) {
  value = Math.ceil(value);
  var val = 0;
  if (!value) {
    val = 0;
  } else if (value > 100) {
    val = 100;
  } else {
    val = value;
  }
  return val;
}
export function totalPercentage(value) {
  value = Math.ceil(value);
  var val = 0;
  if (!value) {
    val = 0;
  } else {
    val = value;
  }
  return val;
}
export function filterMoney(val) {
  return ('' + val // 第一步：转成字符串
  ).replace(/[^\d^\.]+/g, '') // 第二步：把不是数字，不是小数点的过滤掉
  .replace(/^0+(\d)/, '$1') // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
  .replace(/^\./, '0.') // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
  .match(/^\d*(\.?\d{0,2})/g)[0] || ''; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，	而且小数点后面只能有0到2位小数
}
export function doExchange(arr) {
  var len = arr.length;
  // 当数组大于等于2个的时候
  if (len >= 2) {
    // 第一个数组的长度
    var len1 = arr[0].length;
    // 第二个数组的长度
    var len2 = arr[1].length;
    // 2个数组产生的组合数
    var lenBoth = len1 * len2;
    //  申明一个新数组
    var items = new Array(lenBoth);
    // 申明新数组的索引
    var index = 0;
    for (var i = 0; i < len1; i++) {
      for (var j = 0; j < len2; j++) {
        if (arr[0][i] instanceof Array) {
          items[index] = arr[0][i].concat(arr[1][j]);
        } else {
          items[index] = [arr[0][i]].concat(arr[1][j]);
        }
        index++;
      }
    }
    var newArr = new Array(len - 1);
    for (var i = 2; i < arr.length; i++) {
      newArr[i - 1] = arr[i];
    }
    newArr[0] = items;
    return doExchange(newArr);
  } else {
    if (len === 0) {
      return [];
    }
    if (!Array.isArray(arr[0][0])) {
      return arr[0].map(function (item) {
        return item.split();
      });
    }
    return arr[0];
  }
}
export function getFileSize(size) {
  if (!size) return "0K";
  var num = 1024.00; //byte

  if (size < num) return size + "B";
  if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + "K"; //kb
  if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
  if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
  return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
}