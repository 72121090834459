var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.options.length
    ? _c(
        "div",
        {
          staticClass: "table-action",
          style: {
            "justify-content": _vm.boxAlign,
          },
        },
        [
          _vm._l(_vm.options, function (item, index) {
            return [
              !item.hide
                ? _c(
                    "el-button",
                    {
                      key: index,
                      style: { color: item.color },
                      attrs: {
                        loading: item.loading,
                        size: item.size || _vm.size,
                        type: item.type || "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.clickHandler(item, $event)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }