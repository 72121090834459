var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "0 15px" }, on: { click: _vm.toggleClick } },
    [
      _c(
        "svg",
        {
          staticClass: "hamburger",
          class: { "is-active": _vm.isActive },
          attrs: {
            t: "1623771745216",
            viewBox: "0 0 1024 1024",
            xmlns: "http://www.w3.org/2000/svg",
            "p-id": "4419",
            width: "50",
            height: "50",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M1074.631111 885.418667c34.929778 0 63.146667 28.216889 63.146667 63.146666l-0.398222 6.940445c-3.413333 31.687111-30.151111 56.32-62.805334 56.32H63.203556C28.387556 1011.825778 0 983.552 0 948.622222l0.398222-6.940444c3.413333-31.687111 30.151111-56.32 62.805334-56.32zM233.415111 302.08a12.401778 12.401778 0 0 1 20.423111 9.443556v388.892444c0 10.808889-12.743111 16.497778-20.707555 9.216L10.581333 508.700444a12.288 12.288 0 0 1 0.227556-18.659555z m840.988445 267.320889c34.929778 0 63.146667 28.216889 63.146666 63.146667v1.137777a63.146667 63.146667 0 0 1-63.146666 63.146667H442.368a63.146667 63.146667 0 0 1-63.146667-63.146667v-1.137777c0-34.929778 28.16-63.146667 63.146667-63.146667z m0-252.814222c34.929778 0 63.146667 28.273778 63.146666 63.203555v1.137778a63.146667 63.146667 0 0 1-63.146666 63.146667H442.368a63.146667 63.146667 0 0 1-63.146667-63.146667v-1.137778c0-34.929778 28.330667-63.146667 63.146667-63.146666zM1074.574222 0.568889C1109.390222 0.568889 1137.777778 28.785778 1137.777778 63.715556v0.967111a63.146667 63.146667 0 0 1-63.146667 63.203555H63.146667A63.146667 63.146667 0 0 1 0 64.739556V63.715556C0 28.785778 28.216889 0.568889 63.146667 0.568889z",
              fill: "#ffffff",
              "p-id": "4420",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }