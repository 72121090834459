import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 商品管理
 */
import Layout from "@/layout";
var orderNo = 8;
export default {
  path: "/store",
  component: Layout,
  redirect: "/store",
  orderNo: orderNo,
  meta: {
    title: "商品管理",
    icon: "store_icon",
    selectedIcon: "",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/store"
  },
  children: [{
    path: "/uploadedProducts",
    name: "UploadedProducts",
    component: function component() {
      return import("@/views/store/uploadedProducts/index");
    },
    meta: {
      title: "上架商品",
      noCache: true
    },
    children: [{
      path: "/editImgText",
      name: "editImgText",
      hidden: true,
      component: function component() {
        return import("@/views/store/uploadedProducts/editImgText");
      },
      meta: {
        title: "编辑图文",
        noCache: true
      }
    }]
  }, {
    path: "/commoditiesCategory",
    name: "CommoditiesCategory",
    component: function component() {
      return import("@/views/store/commoditiesCategory/index");
    },
    meta: {
      title: "商品类别",
      noCache: true
    }
  }]
};