//
//
//
//
//
//
//

export default {
  name: 'Subtitle',
  props: {
    title: ''
  }
};