import _createForOfIteratorHelper from "/Users/twoha/Desktop/longtou-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
//
//
//
//
//
//
//
//
//
//
//
//

import pathToRegexp from 'path-to-regexp';
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route(to, from) {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      if (this.$route.name) {
        var sessionObj = {};
        if (Object.keys(this.$route.query).length > 0) {
          sessionObj.query = JSON.stringify(this.$route.query);
          sessionObj.fullPath = this.$route.fullPath;
        }
        if (Object.keys(sessionObj).length > 0) {
          if (localStorage.getItem(this.$route.name)) {
            localStorage.removeItem(this.$route.name);
          }
          localStorage.setItem(this.$route.name, JSON.stringify(sessionObj));
        }
      }
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];

      // if (!this.isIndex(first)) {
      //   matched = [{ path: '/stageHome', meta: { title: '首页' } }].concat(matched)
      // }
      // this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
      var list = [];
      var _iterator = _createForOfIteratorHelper(matched),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (item.meta && item.meta.title && item.meta.breadcrumb !== false) {
            if (item.name) {
              var session = localStorage.getItem(item.name);
              if (session) {
                session = JSON.parse(session);
                item.path = session.fullPath ? session.fullPath : item.path;
                if (session.query) {
                  var query = JSON.parse(session.query);
                  item.meta.title = query.breadcrumbTitle ? query.breadcrumbTitle : item.meta.title;
                  item.query = query;
                }
              }
            }
            list.push(item);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.levelList = list;
    },
    isIndex: function isIndex(route) {
      var name = route && route.name;
      if (!name) {
        return false;
      }
      return name.trim().toLocaleLowerCase() === 'Index'.toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
        path = item.path;
      var prefix = '';
      if (item.meta.isHideChildrenPath) {
        prefix = item.meta.hideChildrenPath;
      }
      if (redirect) {
        this.$router.push(prefix + redirect);
        return;
      }
      this.$router.push(this.pathCompile(prefix + path));
    }
  }
};