// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#202020",
	"menuActiveText": "#202020",
	"subMenuActiveText": "#202020",
	"menuBg": "#fff",
	"menuHover": "rgba(79, 118, 197, 0.2)",
	"subMenuBg": "#fff",
	"subMenuHover": "rgba(79, 118, 197, 0.2)",
	"sideBarWidth": "208px"
};
module.exports = exports;
