import _objectSpread from "/Users/twoha/Desktop/longtou-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//

export default {
  name: "BaseSwitch",
  props: {
    value: {
      type: [Number, String, Boolean],
      default: 1
    },
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      defaultConfig: {
        open: 1,
        close: 2
      }
    };
  },
  computed: {
    fieldConfig: function fieldConfig() {
      return _objectSpread(_objectSpread({}, this.defaultConfig), this.config);
    }
  },
  methods: {
    changeHandler: function changeHandler(val) {
      console.log(val, "val");
      var tempVal = val ? this.fieldConfig["open"] : this.fieldConfig["close"];
      console.log(tempVal, "tempVal");
      this.$emit("input", tempVal);
      this.$emit("change", tempVal);
    }
  }
};