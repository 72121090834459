import "/Users/twoha/Desktop/longtou-web/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/twoha/Desktop/longtou-web/node_modules/core-js/modules/es.promise.js";
import "/Users/twoha/Desktop/longtou-web/node_modules/core-js/modules/es.object.assign.js";
import "/Users/twoha/Desktop/longtou-web/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from "vue";
import App from "./App";
import store from "./store";
import router from "./router";
import utils from "./utils";
import "@/icons";
import "@/permission";
import "@/styles/common.scss";
import "@/styles/index.less";
import "@/styles/font.css";
import "@/styles/index.scss";
import "normalize.css/normalize.css";
import ElementUI from "element-ui";
Vue.use(ElementUI);
import "@/utils/addPrototypeMethods";
import * as filters from "./utils/filters.js";
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.prototype.utils = utils;
import Table from "@/components/Table"; //批量注册表格组件
Vue.use(Table);
import BaseDialog from "@/components/BaseDialog/BaseDialog";
import BaseSwitch from "@/components/BaseSwitch/BaseSwitch";
import PageTitle from "@/components/PageTitle/PageTitle";
import PageWrapper from "@/components/PageWrapper/PageWrapper";
Vue.component("BaseDialog", BaseDialog);
Vue.component("BaseSwitch", BaseSwitch);
Vue.component("PageTitle", PageTitle);
Vue.component("PageWrapper", PageWrapper);
import VueAMap from "vue-amap";
Vue.use(VueAMap);
import mixin from "./utils/mixins/mixin";
Vue.mixin(mixin);
import "@/utils/addPrototypeMethods";
VueAMap.initAMapApiLoader({
  key: "d06f0eda3e29e5d13dff3126f493767e",
  plugin: ["AMap.Autocomplete", "AMap.PlaceSearch", "Geocoder", "AMap.Scale", "AMap.OverView", "AMap.ToolBar", "AMap.MapType", "AMap.PolyEditor", "AMap.CircleEditor", "AMap.Geolocation"],
  v: "1.4.4"
});
new Vue({
  el: "#app",
  router: router,
  created: function created() {
    Vue.prototype.$eventhub = this;
  },
  store: store,
  render: function render(h) {
    return h(App);
  }
});