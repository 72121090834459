//
//
//
//
//
//
//

export default {
  name: 'App',
  data: function data() {
    return {
      show: true
    };
  },
  mounted: function mounted() {
    console.log(this.$store.state.user);
  },
  errorCaptured: function errorCaptured(info, vm, data) {
    console.log(info, vm, data);
  }
};