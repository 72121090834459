import variables from '@/styles/element-variables.scss';
import defaultSettings from '@/settings';
import Cookies from 'js-cookie';
var showSettings = defaultSettings.showSettings,
  tagsView = defaultSettings.tagsView,
  fixedHeader = defaultSettings.fixedHeader,
  sidebarLogo = defaultSettings.sidebarLogo;
var state = {
  theme: variables.theme,
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  tagsView: tagsView,
  sidebarLogo: sidebarLogo,
  hideBreadcrumb: false
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      Cookies.set(key, value);
      state[key] = value;
    }
  },
  SET_BREADCRUMB: function SET_BREADCRUMB(state, value) {
    state.hideBreadcrumb = value;
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};