var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.collapse
          ? _c("a", { key: "collapse", staticClass: "sidebar-logo-link" }, [
              _c("img", {
                staticClass: "sidebar-logo-col ",
                attrs: { src: require("@/assets/system_img/logo.png") },
              }),
            ])
          : _c("a", { key: "expand", staticClass: "sidebar-logo-link" }, [
              _c("img", {
                staticClass: "sidebar-logo",
                attrs: { src: require("@assets/system_img/logo.png") },
              }),
              _c("label", [_vm._v("尚品石配运营管理")]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }