import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 财务管理
 */
import Layout from "@/layout";
var orderNo = 2;
export default {
  path: "/finance",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "财务管理",
    icon: "finance_icon",
    selectedIcon: "",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/income"
  },
  children: [{
    path: "/checking",
    name: "checking",
    component: function component() {
      return import("@/views/finance/checking/index");
    },
    meta: {
      title: "平台对账",
      noCache: true
    }
  }, {
    path: "/materialSale",
    name: "materialSale",
    component: function component() {
      return import("@/views/finance/materialSale/index");
    },
    meta: {
      title: "材料销售流水",
      noCache: true
    }
  }, {
    path: "/sale",
    name: "sale",
    component: function component() {
      return import("@/views/finance/sale/index");
    },
    meta: {
      title: "商品销售流水",
      noCache: true
    }
  }, {
    path: "/customFlow",
    name: "customFlow",
    component: function component() {
      return import("@/views/finance/customFlow/index");
    },
    meta: {
      title: "定制加工流水",
      noCache: true
    }
  }, {
    path: "/refund",
    name: "refund",
    component: function component() {
      return import("@/views/finance/refund/index");
    },
    meta: {
      title: "退款流水",
      noCache: true
    }
  }, {
    path: "/customize",
    name: "customize",
    component: function component() {
      return import("@/views/finance/customizeOrder/index");
    },
    meta: {
      title: "自定义加工流水",
      noCache: true
    }
  }, {
    path: "/withdrawApply",
    name: "withdrawApply",
    component: function component() {
      return import("@/views/finance/withdrawApply/index");
    },
    hidden: true,
    meta: {
      title: "提现申请",
      noCache: true
    }
  }, {
    path: "/receipt",
    name: "receipt",
    hidden: true,
    component: function component() {
      return import("@/views/finance/receipt/index");
    },
    meta: {
      title: "开票申请",
      noCache: true
    }
  }]
};