import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//  config:{
// 	noHandle:true,//隐藏操作栏
// 	showIndex:true,//显示序号
// 	selection:true,//可多选
//  selectable:function(),//禁选某些项
//  handleFixed:'right',//固定操作栏

// }
import Bus from './formEventBus';
export default {
  name: 'Table',
  //config 控制按钮和分页信息
  //tableData 表格数据 tableLabel 是标签数据
  props: {
    tableData: Array,
    tableLabel: Array,
    config: Object,
    height: {
      type: [String, Number],
      default: '560px'
    }
  },
  data: function data() {
    return {
      param: {},
      loading: true,
      tableHeight: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    /**
     * 动态动设置表格的高度，解决双手
     */
    setTimeout(function () {
      _this.$nextTick(function () {
        var refInfo = _this.$refs.tableRef;
        _this.tableHeight = "calc(100% - ".concat(refInfo.offsetTop + 14, "px)");
        console.log(_this.tableHeight, 'this.tableHeight');
      });
    }, 0);
  },
  computed: {
    //动态组件
    componentData: function componentData() {
      return function (data, prop) {
        if (!(data !== null && data !== void 0 && data.attrs)) return '';
        var objType = Object.prototype.toString.call(data.attrs);
        if (!['[object Object]', '[object Function]'].includes(objType)) {
          throw new Error('component.attrs 属性必须为Object或者Function格式类型');
        }
        //判断是否为函数还是对象
        return objType === '[object Function]' ? data.attrs(prop) : data.attrs;
      };
    }
  },
  created: function created() {
    var _this2 = this;
    Bus.$on('getParam', function (data) {
      _this2.param = data;
      if (data.pageNum) {
        _this2.config.page = data.pageNum;
      }
    });
  },
  methods: {
    //详情
    handleDetail: function handleDetail(row) {
      this.$emit('goDetail', row);
    },
    //更新
    handleEdit: function handleEdit(row) {
      this.$emit('edit', row);
    },
    //删除
    handleDelete: function handleDelete(row) {
      this.$emit('del', row);
    },
    //分页
    changePage: function changePage(page) {
      this.param.pageNum = page;
      this.$emit('changePage', {
        page: page,
        param: this.param
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val);
    }
  }
};