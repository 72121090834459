import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 定制加工管理
 */
import Layout from "@/layout";
var orderNo = 4;
export default {
  path: "/customizeOrder",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "自定义加工订单管理",
    icon: "commodities_icon",
    selectedIcon: " ",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/customizeOrder"
  },
  children: [{
    path: "/customizeOrderList",
    name: "customizeOrderList",
    component: function component() {
      return import("@/views/customizeOrder/customizeOrderList");
    },
    meta: {
      title: "自定义加工订单",
      noCache: true
    }
  }, {
    path: "/customizeOrderSend",
    name: "customizeOrderSend",
    component: function component() {
      return import("@/views/customizeOrder/customizeOrderSend");
    },
    meta: {
      title: "自定义加工发货订单",
      noCache: true
    }
  }, {
    path: "/customizeOrderAdd",
    name: "customizeOrderAdd",
    component: function component() {
      return import("@/views/customizeOrder/customizeOrderAdd");
    },
    hidden: true,
    meta: {
      title: "新增订单",
      hidden: true,
      noCache: true
    }
  }, {
    path: "/customizeOrderDetail",
    name: "customizeOrderDetail",
    component: function component() {
      return import("@/views/customizeOrder/customizeOrderDetail");
    },
    hidden: true,
    meta: {
      title: "订单详情",
      hidden: true,
      noCache: true
    }
  }]
};