import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 运营管理
 */
import Layout from "@/layout";
var orderNo = 1;
export default {
  path: "/operate",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "运营管理",
    icon: "operate_icon",
    selectedIcon: "",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/operate"
  },
  children: [{
    path: "/banner",
    name: "banner",
    component: function component() {
      return import("@/views/operate/banner/index");
    },
    meta: {
      title: "banner管理",
      noCache: true
    }
  }, {
    path: "/hotPartition",
    name: "hotPartition",
    component: function component() {
      return import("@/views/operate/hotPartition/index");
    },
    meta: {
      title: "热门分区管理",
      noCache: true
    }
  }, {
    path: "/color",
    name: "color",
    component: function component() {
      return import("@/views/operate/color/index");
    },
    meta: {
      title: "颜色分类管理",
      noCache: true
    }
  }, {
    path: "/hotStone",
    name: "hotStone",
    component: function component() {
      return import("@/views/operate/hotStone/index");
    },
    meta: {
      title: "热推石种管理",
      noCache: true
    }
  }, {
    path: "/hotSale",
    name: "hotSale",
    component: function component() {
      return import("@/views/operate/hotSale/index");
    },
    meta: {
      title: "热销成品定制通道",
      noCache: true
    }
  }, {
    path: "/addSale",
    name: "addSale",
    hidden: true,
    component: function component() {
      return import("@/views/operate/hotSale/addSale");
    },
    meta: {
      title: "新增热销成品",
      noCache: true
    }
  }]
};