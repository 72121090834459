import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 定制管理
 */
import Layout from "@/layout";
var orderNo = 8;
export default {
  path: "/customMade",
  component: Layout,
  redirect: "/customMachining",
  orderNo: orderNo,
  meta: {
    title: "定制管理",
    icon: "store_icon",
    selectedIcon: " ",
    noCache: true,
    id: "2000"
  },
  children: [{
    path: "/Machining",
    name: "Machining",
    component: function component() {
      return import("@/views/customMade/customMachining/index");
    },
    redirect: "/customMachining",
    meta: {
      title: "定制加工",
      noCache: true
    },
    children: [{
      path: "/customMachining",
      name: "CustomMachining",
      component: function component() {
        return import("@/views/customMade/customMachining/customMachining");
      },
      hidden: true,
      meta: {
        activeMenu: "/Machining",
        hidden: true,
        noCache: true
      }
    }, {
      path: "/customMachining_add",
      name: "MachiningDetail",
      component: function component() {
        return import("@/views/customMade/customMachining/detail");
      },
      hidden: true,
      meta: {
        activeMenu: "/Machining",
        title: "新增定制品",
        noCache: true
      }
    }, {
      path: "/customMachining_edit",
      name: "MachiningDetail",
      component: function component() {
        return import("@/views/customMade/customMachining/detail");
      },
      hidden: true,
      meta: {
        activeMenu: "/Machining",
        title: "编辑定制品",
        noCache: true
      }
    }]
  }, {
    path: "/processLibrary",
    name: "ProcessLibrary",
    component: function component() {
      return import("@/views/customMade/processLibrary/index");
    },
    meta: {
      title: "加工工艺库",
      noCache: true
    }
  }]
};