var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  userId: function userId(state) {
    return state.user.userId;
  },
  rolesName: function rolesName(state) {
    return state.user.rolesName;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  menu: function menu(state) {
    return state.user.menu;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  orgInfo: function orgInfo(state) {
    return state.user.orgInfo;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  }
};
export default getters;