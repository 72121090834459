import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 材料订单管理
 */
import Layout from "@/layout";
var orderNo = 5;
export default {
  path: "/materialOrder",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "材料订单管理",
    icon: "order_icon",
    selectedIcon: "",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/materialOrder"
  },
  children: [{
    path: "/materialOrderSale",
    name: "materialOrderSale",
    component: function component() {
      return import("@/views/materialOrder/materialOrderSale/index");
    },
    meta: {
      title: "材料销售订单",
      noCache: true
    },
    children: [{
      path: "/materialOrderInfo",
      name: "materialOrderInfo",
      component: function component() {
        return import("@/views/materialOrder/materialOrderSale/materialOrderInfo");
      },
      hidden: true,
      meta: {
        activeMenu: "/materialOrderSale",
        title: "材料订单详情",
        noCache: true
      }
    }]
  }, {
    path: "/materialSendList",
    name: "materialSendList",
    component: function component() {
      return import("@/views/materialOrder/materialSendList/index");
    },
    meta: {
      title: "材料发货单",
      noCache: true
    }
  }]
};