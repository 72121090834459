import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 系统设置
 */
import Layout from "@/layout";
var orderNo = 0;
export default {
  path: "/setting",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "系统设置",
    icon: "setting_icon",
    selectedIcon: "",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/setting"
  },
  children: [{
    path: "/my",
    name: "my",
    component: function component() {
      return import("@/views/setting/my");
    },
    meta: {
      title: "我的信息",
      noCache: true
    }
  }, {
    path: "/platform",
    name: "platform",
    component: function component() {
      return import("@/views/setting/platform/index");
    },
    meta: {
      title: "平台信息",
      noCache: true
    }
  }, {
    path: "/employeeAccount",
    name: "employeeAccount",
    component: function component() {
      return import("@/views/setting/employeeAccount");
    },
    meta: {
      title: "员工账号",
      noCache: true
    }
  }, {
    path: "/role",
    name: "role",
    component: function component() {
      return import("@/views/setting/role");
    },
    meta: {
      title: "角色管理",
      noCache: true
    }
  }]
};