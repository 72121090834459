var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-switch", {
    attrs: {
      value: _vm.value == _vm.fieldConfig["open"],
      "active-text": _vm.fieldConfig["openText"],
      "inactive-text": _vm.fieldConfig["closeText"],
      "active-color": "#13ce66",
      "inactive-color": "#ff4949",
    },
    on: { input: _vm.changeHandler },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }