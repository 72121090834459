import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import Layout from "@/layout";
import customOrder from "./module/customOrder"; //定制加工订单管理
import finance from "./module/finance";
import material from "./module/material";
import commodities from "./module/commodities"; //商品库存
import store from "./module/store";
import user from "./module/user";
import materialOrder from "./module/materialOrder";
import order from "./module/order";
import operate from "./module/operate";
import setting from "./module/setting";
import customMade from "./module/customMade";
import customizeOrder from "./module/customizeOrder";
import varietyCase from "./module/varietyCase";

/**
 * 异步路由
 * @property { Array } menuModules - 菜单模块
 * @property { String } menuModules[].path - 路由的路径
 * @property { String } menuModules[].component - 组件地址
 * @property { Number } menuModules[].orderNo - 排序字段，数字越大越靠前 不设置就按照默认顺序 从上到下
 * @property { Object } menuModules[].meta
 */

export var asyncRoutes = [material, commodities, store, customMade, user, materialOrder, customizeOrder, order, customOrder, finance, operate, setting
// varietyCase
];

//常规路由
export var constantRoutes = [{
  path: "/",
  component: Layout,
  hidden: true,
  redirect: "/login"
}, {
  path: "/login",
  component: function component() {
    return import("@/views/login/index");
  },
  hidden: true,
  meta: {
    title: "平台登录"
  }
}, {
  path: "/redirect",
  component: Layout,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return import("@/views/universal/redirect");
    }
  }]
}, {
  path: "/download",
  component: function component() {
    return import("@/views/universal/download");
  },
  hidden: true
},
// {
//   path: "/test",
//   component: () => import("@/views/test"),
//   hidden: true,
// },
{
  path: "/404",
  component: function component() {
    return import("@/views/universal/404");
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return import("@/views/universal/401");
  },
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    mode: "history",
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}
export default router;