import request from "@/utils/request";
var internal = {
  type: "home"
};
// 退出登录
export function logout(data) {
  return request({
    url: "/user/logOut",
    method: "post",
    data: {},
    internal: internal
  });
}

//获取用户信息
export function getUser(data) {
  return request({
    url: "/admin/infoById",
    method: "post",
    data: data,
    internal: internal
  });
}
// 团队查询
export function getOrg(data) {
  return request({
    url: "/identity/userCurrentTeamMessage",
    method: "post",
    data: data,
    internal: internal
  });
}
export function passwordLogin(data) {
  return request({
    url: "/adminauth/adminLogin",
    method: "post",
    data: data,
    internal: internal
  });
}
export function resetPassword(data) {
  return request({
    url: "/userAuth/resetPassword",
    method: "post",
    data: data,
    internal: internal
  });
}
export function sendSecVerifyCode(data) {
  return request({
    url: "/hmSms/sendSecVerifyCode",
    method: "post",
    data: data,
    internal: internal
  });
}
export function checkSmsVerifyCode(data) {
  return request({
    url: "/hmSms/checkSmsVerifyCode",
    method: "post",
    data: data,
    internal: internal
  });
}
export function getMyOrgList(data) {
  return request({
    url: "/org/myCreateOrg",
    method: "post",
    data: data,
    internal: internal
  });
}
export function atPresentIdentity(data) {
  return request({
    url: "/identity/atPresentIdentity",
    method: "post",
    data: data,
    internal: internal
  });
}
//上传文件
export function uploadFile(data) {
  return request({
    url: "/file/uploadFile",
    method: "post",
    data: data,
    internal: internal,
    upload: true
  });
}

//上获取字典列表
export function getDicList(data) {
  return request({
    url: "/dic/getDicList",
    method: "post",
    data: data
  });
}
// {dicType:'PLATFORM_CONFIG'}
// (PLATFORM_CONFIG)平台设置，
// (WITHDRAWAL_CONFIG)提现设置，
// (SETTLEMENT_CONFIG)结算设置，
// (RELEGATION_CONFIG)保级设置，
// (IDENTITY_CONFIG)身份设置，
// (DIRECT_UPGRADE_CONFIG)直冲直升规则，
// (BASIC_UPGRADE_CONFIG)基础升级规则
//更新字典列表
export function dicUpdateBatch(data) {
  return request({
    url: "/dic/updateBatch",
    method: "post",
    data: data
  });
}

// 发送短信验证码
export function sendCode(data) {
  return request({
    url: "/sms/sendCode",
    method: "post",
    data: data,
    auto: true
  });
}

// 验证手机验证码
export function checkCode(data) {
  return request({
    url: "/sms/checkCode",
    method: "post",
    data: data
  });
}

// 获取分享Code
export function setShareCode(data) {
  return request({
    url: "/user/setShareCode",
    method: "post",
    data: data
  });
}

//获取省市区地址
export function getNationwide(data) {
  return request({
    url: "/file/getNationwide",
    method: "post",
    data: data,
    internal: internal,
    upload: true
  });
}