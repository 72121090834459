//
//
//
//
//
//
//
//

import Validate from '@/utils/validate';
var validate = new Validate();
export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  methods: {
    linkProps: function linkProps(url) {
      if (validate.isExternal(url)) {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener'
        };
      }
      return {
        is: 'router-link',
        to: url
      };
    }
  }
};