import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 材料库存
 */
import Layout from "@/layout";
var orderNo = 10;
export default {
  path: "/material",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "材料库存",
    icon: "material_icon",
    selectedIcon: " ",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/material"
  },
  children: [{
    path: "/addMaterial",
    name: "AddMaterial",
    component: function component() {
      return import("@/views/material/addMaterial");
    },
    hidden: true,
    orderNo: 4,
    meta: {
      title: "添加物料",
      breadcrumb: true,
      noCache: true
    }
  }, {
    path: "/platStock",
    name: "platStock",
    component: function component() {
      return import("@/views/material/platStock/index");
    },
    meta: {
      title: "平台库存大板",
      noCache: true
    },
    children: [{
      path: "/platStockEdit",
      name: "platStockEdit",
      hidden: true,
      component: function component() {
        return import("@/views/material/platStock/editPlatStock");
      },
      meta: {
        title: "上架大板",
        noCache: true
      }
    }]
  }, {
    path: "/customer",
    name: "customer",
    component: function component() {
      return import("@/views/material/customer/index");
    },
    meta: {
      title: "客户暂存大板",
      noCache: true
    }
  }, {
    path: "/warehousing",
    name: "warehousing",
    component: function component() {
      return import("@/views/material/warehousing/index");
    },
    meta: {
      title: "入库管理",
      noCache: true
    },
    children: [{
      path: "/importBan",
      name: "importBan",
      hidden: true,
      component: function component() {
        return import("@/views/material/warehousing/importBan");
      },
      meta: {
        title: "大板导入",
        noCache: true
      }
    }, {
      path: "/housingInfo",
      name: "housingInfo",
      hidden: true,
      component: function component() {
        return import("@/views/material/warehousing/housingInfo");
      },
      meta: {
        title: "入库单详情",
        noCache: true
      }
    }]
  }, {
    path: "/outbound",
    name: "outbound",
    component: function component() {
      return import("@/views/material/outbound/index");
    },
    meta: {
      title: "出库管理",
      noCache: true
    },
    children: [{
      path: "/addBound",
      name: "addBound",
      hidden: true,
      component: function component() {
        return import("@/views/material/outbound/addBound");
      },
      meta: {
        title: "新建出库",
        noCache: true
      }
    }, {
      path: "/boundInfo",
      name: "boundInfo",
      hidden: true,
      component: function component() {
        return import("@/views/material/outbound/boundInfo");
      },
      meta: {
        title: "出库单详情",
        noCache: true
      }
    }]
  }, {
    path: "/allocation",
    name: "allocation",
    component: function component() {
      return import("@/views/material/allocation/index");
    },
    meta: {
      title: "调拨管理",
      noCache: true
    },
    children: [{
      path: "/newTransfer",
      name: "newTransfer",
      hidden: true,
      component: function component() {
        return import("@/views/material/allocation/newTransfer");
      },
      meta: {
        title: "新建调拨",
        noCache: true
      }
    }, {
      path: "/transferDetail",
      name: "transferDetail",
      hidden: true,
      component: function component() {
        return import("@/views/material/allocation/transferDetail");
      },
      meta: {
        title: "调拨详情",
        noCache: true
      }
    }]
  }, {
    path: "/takeApply",
    name: "takeApply",
    component: function component() {
      return import("@/views/material/takeApply/index");
    },
    meta: {
      title: "提货申请",
      noCache: true
    },
    children: [{
      path: "/takeInfo",
      name: "takeInfo",
      hidden: true,
      component: function component() {
        return import("@/views/material/takeApply/takeInfo");
      },
      meta: {
        title: "提货详情",
        noCache: true
      }
    }]
  }, {
    path: "/warehouse",
    name: "warehouse",
    component: function component() {
      return import("@/views/material/warehouse/index");
    },
    meta: {
      title: "仓库管理",
      noCache: true
    },
    children: [{
      path: "/addWarehouse",
      name: "addWarehouse",
      hidden: true,
      component: function component() {
        return import("@/views/material/warehouse/addWarehouse");
      },
      meta: {
        title: "编辑仓库",
        noCache: true
      }
    }]
  }, {
    path: "/stoneManage",
    name: "stoneManage",
    component: function component() {
      return import("@/views/material/stoneManage/index");
    },
    meta: {
      title: "石种管理",
      noCache: true
    }
  }, {
    path: "/partitionedManage",
    name: "partitionedManage",
    component: function component() {
      return import("@/views/material/partitionedManage/index");
    },
    meta: {
      title: "分区管理",
      noCache: true
    }
  }, {
    path: "/customCase",
    name: "customCase",
    component: function component() {
      return import("@/views/material/customCase/index");
    },
    meta: {
      title: "定制案例",
      noCache: true
    }
  }, {
    path: "/blockTagManage",
    name: "blockTagManage",
    component: function component() {
      return import("@/views/material/blockTagManage/index");
    },
    meta: {
      title: "荒料标签管理",
      noCache: true
    }
  }]
};