import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 定制加工管理
 */
import Layout from "@/layout";
var orderNo = 3;
export default {
  path: "/customOrder",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "定制加工订单",
    icon: "commodities_icon",
    selectedIcon: " ",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/customOrder"
  },
  children: [{
    path: "/processingDeliver",
    name: "ProcessingDeliver",
    component: function component() {
      return import("@/views/customOrder/processingDeliver/processingDeliver");
    },
    meta: {
      title: "加工发货单",
      noCache: true
    }
  }, {
    path: "/processingOrderIndex",
    name: "ProcessingOrderIndex",
    orderNo: 1,
    component: function component() {
      return import("@/views/customOrder/processingOrder/index");
    },
    redirect: "/processingOrder",
    meta: {
      title: "定制加工订单",
      noCache: true
    },
    children: [{
      path: "/processingOrder",
      name: "ProcessingOrder",
      component: function component() {
        return import("@/views/customOrder/processingOrder/processingOrder");
      },
      hidden: true,
      meta: {
        activeMenu: "/processingOrderIndex",
        hidden: true,
        noCache: true
      }
    }, {
      path: "/processingOrder_see",
      name: "ProcessingOrderDetail",
      component: function component() {
        return import("@/views/customOrder/processingOrder/detail");
      },
      hidden: true,
      meta: {
        activeMenu: "/processingOrderIndex",
        title: "订单详情",
        noCache: true
      }
    }]
  }]
};