import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 商品库存
 */
import Layout from "@/layout";
var orderNo = 9;
export default {
  path: "/commodities",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "商品库存",
    icon: "commodities_icon",
    selectedIcon: " ",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/commodities"
  },
  children: [{
    path: "/commoditiesList",
    name: "CommoditiesList",
    component: function component() {
      return import("@/views/commodities/commoditiesList/index");
    },
    meta: {
      title: "商品列表",
      noCache: true
    },
    children: [{
      path: "/addGoods",
      name: "AddGoods",
      hidden: true,
      component: function component() {
        return import("@/views/commodities/commoditiesList/AddGoods/index");
      },
      meta: {
        title: "新增商品",
        noCache: true
      }
    }, {
      path: "/editGoods",
      name: "editGoods",
      hidden: true,
      component: function component() {
        return import("@/views/commodities/commoditiesList/AddGoods/index");
      },
      meta: {
        title: "编辑商品",
        noCache: true
      }
    }, {
      path: "/putOn",
      name: "putOn",
      hidden: true,
      component: function component() {
        return import("@/views/commodities/commoditiesList/putOn");
      },
      meta: {
        title: "上架商品",
        noCache: true
      }
    }]
  }, {
    path: "/streamingList",
    name: "StreamingList",
    component: function component() {
      return import("@/views/commodities/streamingList/index");
    },
    meta: {
      title: "库存流水",
      noCache: true
    }
  }, {
    path: "/brandsList",
    name: "BrandsList",
    component: function component() {
      return import("@/views/commodities/brandsList/index");
    },
    meta: {
      title: "品牌库",
      noCache: true
    }
  }, {
    path: "/suppliersList",
    name: "SuppliersList",
    component: function component() {
      return import("@/views/commodities/suppliersList/index");
    },
    meta: {
      title: "供应商管理",
      noCache: true
    }
  }, {
    path: "/unitList",
    name: "UnitList",
    component: function component() {
      return import("@/views/commodities/unitList/index");
    },
    meta: {
      title: "单位库",
      noCache: true
    }
  }, {
    path: "/tagsList",
    name: "TagsList",
    component: function component() {
      return import("@/views/commodities/tagsList/index");
    },
    meta: {
      title: "标签库",
      noCache: true
    }
  }, {
    path: "/shippingTemplate",
    name: "ShippingTemplate",
    hidden: true,
    component: function component() {
      return import("@/views/commodities/shippingTemplate/index");
    },
    meta: {
      title: "运费模板",
      noCache: true
    },
    children: [{
      path: "/addTemplate",
      name: "addTemplate",
      component: function component() {
        return import("@/views/commodities/shippingTemplate/addTemplate");
      },
      hidden: true,
      meta: {
        title: "新增模板",
        noCache: true
      }
    }]
  }, {
    path: "/limitTemplate",
    name: "limitTemplate",
    hidden: true,
    component: function component() {
      return import("@/views/commodities/limitTemplate/index");
    },
    meta: {
      title: "限购模板",
      noCache: true
    }
  }]
};