import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 导入library文件夹下的所有组件
// 批量导入需要使用一个函数 require.context(dir,deep,matching)
// 参数：1. 目录  2. 是否加载子目录  3. 加载的正则匹配
var importFn = require.context("./", false, /\.vue$/);
// console.dir(importFn.keys()) 文件名称数组

export default {
  install: function install(app) {
    // 批量注册全局组件
    importFn.keys().forEach(function (key) {
      // 导入组件
      var component = importFn(key).default;
      // 注册组件
      app.component(component.name, component);
    });
  }
};