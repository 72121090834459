import "core-js/modules/es.array.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 表格中的操作按钮组件
 * @description 用于表格中的操作项中
 * @author copyman
 * @property { Array } options - 操作按钮配置选项
 * @property { String } options[].name - 按钮名称
 * @property { String } options[].type - 按钮类型
 * @property { Boolean } options[].hide - 按钮是否隐藏
 * @property { Boolean } options[].onClick - 按钮是否单独设置点击事件
 * @event click - 按钮点击事件
 */
export default {
  name: "TableAction",
  props: {
    size: {
      type: String,
      default: "mini",
      validator: function validator(value) {
        return ["medium", "small", "mini"].includes(value);
      }
    },
    align: {
      type: String,
      default: "center",
      validator: function validator(value) {
        return ["left", "right", "center"].includes(value);
      }
    },
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    boxAlign: function boxAlign() {
      return {
        left: "flex-start",
        right: "flex-end",
        center: "center"
      }[this.align];
    }
  },
  methods: {
    clickHandler: function clickHandler(item) {
      if (item.onClick) {
        return item.onClick();
      } else {
        this.$emit("click", item);
      }
    }
  }
};