import _objectSpread from "/Users/twoha/Desktop/longtou-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { asyncRoutes, constantRoutes } from "@/router";
import routerSetting from "@/settings/routerSetting";
// 菜单排序方法
var sortMenuList = function sortMenuList() {
  var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var j = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var temp = list.children || [];
  temp.sort(function (a, b) {
    return (b.orderNo || 0) - (a.orderNo || 0);
  });
  for (var i = 0; i < temp.length; i++) {
    sortMenuList(temp[i]);
    j++;
  }
  if (j >= temp.length) {
    return temp;
  }
};
var routeList = sortMenuList({
  children: asyncRoutes
});
export function filterAsyncRoutes(routes, data) {
  var res = [];
  var menuList = data.menuList;
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (tmp.children) {
      tmp.children = filterAsyncRoutes(tmp.children, data);
    }
    //判断是否是后端路由
    if (routerSetting.permissionMode === "back") {
      menuList = menuList.map(function (item, i) {
        if (item.menuId == route.meta.id) {
          res.push(tmp);
        }
        return item;
      });
    } else {
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes = filterAsyncRoutes(routeList, data);
      commit("SET_ROUTES", accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};