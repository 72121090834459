import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * @description 用户管理
 */
import Layout from "@/layout";
var orderNo = 6;
export default {
  path: "/user",
  component: Layout,
  orderNo: orderNo,
  meta: {
    title: "用户管理",
    icon: "user_icon",
    selectedIcon: "",
    isHideChildrenPath: false,
    noCache: true,
    hideChildrenPath: "/user"
  },
  children: [{
    path: "/userList",
    name: "userList",
    component: function component() {
      return import("@/views/user/userList");
    },
    meta: {
      title: "用户列表",
      noCache: true
    },
    children: [{
      path: "/userInfo",
      name: "userInfo",
      component: function component() {
        return import("@/views/user/userInfo");
      },
      hidden: true,
      meta: {
        title: "用户详情",
        noCache: true
      }
    }, {
      path: "/userStock",
      name: "userStock",
      component: function component() {
        return import("@/views/user/userStock");
      },
      hidden: true,
      meta: {
        title: "用户库存明细",
        noCache: true
      }
    }]
  }]
};