import _objectSpread from "/Users/twoha/Desktop/longtou-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from "@/styles/variables.scss";
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permission_routes", "sidebar"])), {}, {
    currentRoutePath: function currentRoutePath() {
      var route = this.$route;
      var path = route.path;
      var routePath = path.split('/');
      routePath = routePath[0] ? routePath[0] : routePath[1];
      return routePath;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};