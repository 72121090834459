var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("label", { staticClass: "n_title" }),
      _c("div", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "ch_userInfo", attrs: { trigger: "click" } },
            [
              _c(
                "div",
                { staticClass: "cu_wrapper" },
                [
                  _c(
                    "el-image",
                    {
                      staticClass: "userImg",
                      attrs: { src: _vm.userInfo.avatar },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@assets/system_img/logo.png"),
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _c("label", [
                    _vm._v(_vm._s(_vm.userInfo.nickname)),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "cu_dropdown",
                  attrs: { slot: "dropdown", trigger: "click" },
                  slot: "dropdown",
                },
                [
                  _c("el-dropdown-item", { staticClass: "cu_itemHeader" }, [
                    _c("label", [
                      _vm._v("昵称:" + _vm._s(_vm.userInfo.username) + " "),
                    ]),
                    _c("label", [
                      _vm._v("角色:" + _vm._s(_vm.userInfo.roleName)),
                      _vm.userInfo.bgRoleType === 1
                        ? _c("label", { staticClass: "red" }, [
                            _vm._v("(主账号)"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [_c("span", [_vm._v("退出登录")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }